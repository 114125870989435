import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import ChineseLayout from "../../components/ChineseLayout"
// import Inactivity from "../../components/Inactivity"

class HomeChinese extends React.Component {
  componentDidMount() {
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <body className="home zh" />
        </Helmet>
        <main role="main">
          <section className="hero-area home-hero-area">
            <div className="home-hero-area__inner">
              <h1>
                <strong>Baby+Me亲子体验店</strong>
                <br />
                @ 史基浦机场
              </h1>
              <p>在这里，我们为家长和宝贝们提供前所未有的浸入式体验、科学育儿知识。</p>
              <Link to="/zh/event-experience" className="cta -white">阅读更多</Link>
            </div>
          </section>

          <section className="main-item">
            <i className="fas fa-plane"></i>
            <span className="main-item__title">
              飞行提示
            </span>
            <Link to="/zh/tips-for-flying" className="main-item__link">儿童无忧出行乘机小贴士</Link>
          </section>

          <section className="menu-list">
            <Link to="/zh/about-us" className="menu-button -white" title="关于我们">关于我们</Link>
            <Link to="/zh/our-nutrition-pledge" className="menu-button -white" title="婴幼儿营养承诺">婴幼儿营养承诺</Link>
          </section>

        </main>
      </ChineseLayout>
    )
  }
}

export default HomeChinese